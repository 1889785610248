import { defineStore } from "pinia";
import { Ref, ref } from "vue";

export const useDialogsStore = defineStore("dialogs", () => {
  const dialogs: Ref<boolean[]> = ref([]);

  function openModal(key: number) {
    closeAllModals();
    dialogs.value[key] = true;
  }

  function closeAllModals() {
    dialogs.value.forEach((booleanValue: boolean, index: number) => {
      dialogs.value[index] = false;
    });
  }

  function openModalNoClosing(key: number) {
    dialogs.value[key] = true;
  }

  function closeOnlyOneModal(key: number) {
    dialogs.value[key] = false;
  }

  function createNewDialog() {
    dialogs.value.push(false);
    return dialogs.value.length - 1;
  }

  return {
    dialogs,
    openModal,
    openModalNoClosing,
    closeOnlyOneModal,
    closeAllModals,
    createNewDialog,
  };
});
